<template>
  <div class="h-full">
    <iframe :src="iframeURL" height="100%" width="100%" />    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountAPI from '../../../../api/account'

export default {
  data() {
    return { 
     
    };
  },
  mounted() {
    this.checkiframeURLPresence()
  },
  computed: {
    iframeURL() {
      return window.chatwootConfig.votingUrl 
    },
  },
  methods: {
    
    checkiframeURLPresence() {
      if(!window.chatwootConfig.votingUrl) {
        location.reload()
      }
    }
  }
};
</script>

<style scoped>
.h-full {
  height: 100%;
}
</style>
