import { frontendURL } from '../../../../helper/URLHelper';

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: [],
  menuItems: [
    // {
    //   icon: 'arrow-swap',
    //   label: 'ONGOING',
    //   key: 'ongoingCampaigns',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/campaigns/ongoing`),
    //   toStateName: 'settings_account_campaigns',
    // },
    // {
    //   key: 'oneOffCampaigns',
    //   icon: 'sound-source',
    //   label: 'ONE_OFF',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
    //   toStateName: 'one_off',
    // },
  ],
});

export default campaigns;
