<!-- components/DrawerToggleButton.vue -->
<template>
  <button
    class="top-4 left-4 z-50 text-sm bg-transparent text-black-900 dark:text-slate-300 rounded-md md:hidden"
    @click="toggleDrawer"
  >
    <fluent-icon icon="menu" />
  </button>
</template>

<script>
export default {
  name: 'DrawerToggleButton',
  methods: {
    toggleDrawer() {
      this.$store.dispatch('navButton/toggleDrawer'); // Dispatch the toggleDrawer action using Vuex convention
    },
  },
};
</script>
