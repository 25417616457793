import types from '../mutation-types';
// Initial state
const state = {
  isDrawerOpen: false,
};

// Getters
const getters = {
  isDrawerOpen: (_state) => _state.isDrawerOpen,
};

// Actions
const actions = {
  toggleDrawer({ commit }) {
    commit(types.TOGGLE_DRAWER);
  },
  closeDrawer({ commit }) {
    commit(types.CLOSE_DRAWER);
  },
};

// Mutations
const mutations = {
  [types.TOGGLE_DRAWER]: (_state) => {
    _state.isDrawerOpen = !_state.isDrawerOpen;
  },
  [types.CLOSE_DRAWER]: (_state) => {
    _state.isDrawerOpen = false;
  },
};

// Export module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
