<template>
  <woot-modal
    v-if="announcement"
    :show.sync="showAnnouncementModal"
    :on-close="closeAnnouncementModal"
    size="medium"
  >
    <button 
      v-if="showButton" 
      @click="showAllAnnouncement"
      class="button absolute ltr:left-2 rtl:right-2 top-2 z-10 p-0 clear"
    >
      {{ $t('ANNOUNCEMENT.ALL_ANNOUNCEMENTS')}}
    </button>
    <woot-modal-header class="center-h2 text-center test" :header-title="announcement.title" />
      
    <div class="px-8 pt-4 pb-8 text-center">
      <div v-html="parsedDescription(announcement.description)"></div>
      <div v-if="announcement.video_url" class="flex justify-center mt-4">
        <iframe
          :src="announcement.video_url"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          width="560" height="315"
          allowfullscreen
          style="position: relative; top: 0; left: 0;"
        ></iframe>
      </div>
      <div v-if="announcement.file_url" class="flex justify-center mt-4">
        <img 
          v-if="announcement.content_type.includes('image')"
          :src="announcement.file_url" 
          alt="" 
        >
        <video :src="announcement.file_url" v-if="announcement.content_type.includes('video')" controls></video>
      </div>
    </div>
    <div v-if="announcement.custom_attributes" class="flex flex-wrap justify-center my-4">
      <div v-for="(button, index) in announcement.custom_attributes.buttons" :key="index">
        <a 
          v-if="button.type=='URL'" 
          :href="button.url" target="_blank"
          class="button clear outline m-2"
        > 
          {{ button.innerText }}
        </a>
      </div>
    </div>
  </woot-modal>
</template>

<script>
export default {
  computed:{
    hasCustomAttributes(){
      return this.announcement.custom_attributes? true : false
    }
  },
  data(){
    return {
      showAnnouncementModal: false
    }
  },
  props: {
    showAnnouncement: {
      type: Boolean,
      default: false
    },
    announcement: {
      type: Object,
      default: () => {}
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeAnnouncementModal() {
      this.showAnnouncementModal = false
    },
    openAnnouncementModal() {
      this.showAnnouncementModal = true
    },
    parsedDescription(string) {
      return (string || '').replace(/\r\n/g, '<br>')
    },
    showAllAnnouncement() {
      this.showAnnouncementModal = false;
      this.$router.push({
         name: 'announcements' 
      })
    }
  }
}
</script>
