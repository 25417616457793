/* global axios */
import ApiClient from "./ApiClient";

class LinksAPI extends ApiClient {
  constructor() {
    super('link_generator', { accountScoped: true });
  }

  get({ page = 1 } = {}) {
    const urlWithParams = `${this.url}?page=${page}`;
    return axios.get(urlWithParams);
  }
 
}

export default new LinksAPI();
