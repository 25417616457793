<template>
  <div class="flex-1 overflow-auto p-4 flex justify-between flex-col">
    <!-- List Audit Logs -->
    <div>
      <div>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('AUDIT_LOGS.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList"
          class="woot-table w-full"
        >
          <colgroup>
            <col class="w-[30%]" />
            <col class="w-[30%]" />
            <col class="w-[20%]" />
            <col class="w-[20%]" />
          </colgroup>
          <thead>
            <!-- Header -->
            <th>
              {{ $t('ANNOUNCEMENT.TABLE.TITLE')}}

            </th>
            <th>
              {{ $t('ANNOUNCEMENT.TABLE.DESCRIPTION')}}
            </th>
            <th>
              {{ $t('ANNOUNCEMENT.TABLE.CREATED_AT')}}
            </th>
          </thead>
          <tbody>
            <tr v-for="announcement in records" :key="announcement.id" class="cursor-pointer" >
              <td class="whitespace-nowrap break-all">
                {{ truncateText(announcement.title, 30)}}
              </td>
              <td class=" w-[8.75rem] whitespace-nowrap overflow-hidden text-overflow-ellipsis break-all">
                 {{ truncateText(announcement.description || '', 50) }}
              </td>
              <td class="whitespace-nowrap break-all">
                {{
                  dynamicTime(new Date(announcement.created_at).getTime()/1000)                  
                }}
              </td>
              <td>
                <button 
                  @click="openAnnouncementModal(announcement)"
                  class="button clear"
                >
                  {{ $t('ANNOUNCEMENT.OPEN_ANNOUNCEMENT')}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <table-footer
      :current-page="Number(meta.currentPage)"
      :total-count="meta.totalEntries"
      :page-size="meta.perPage"
      class="dark:bg-slate-900"
      @page-change="onPageChange"
    />
    <announcement-modal ref="announcementModal" :announcement="announcement" :show-announcement="showAnnouncement"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import AnnouncementModal from 'shared/components/AnnouncementModal.vue';

export default {
  components: {
    TableFooter,
    AnnouncementModal,
  },
  mixins: [alertMixin, timeMixin],
  beforeRouteEnter(to, from, next) {
    // Fetch Audit Logs on page load without manual refresh
    next(vm => {
      vm.fetchAuditLogs();
    });
  },
  data() {
    return {
      loading: {},
      auditLogsAPI: {
        message: '',
      },
      announcement: null,
      showAnnouncement: false
    };
  },
  computed: {
    ...mapGetters({
      records: 'announcements/getAll',
      uiFlags: 'announcements/getUIFlags',
      meta: 'announcements/getMeta',
      agentList: 'agents/getAgents',
    }),
  },
  mounted() {
    // Fetch API Call
  },
  methods: {
    fetchAuditLogs() {
      const page = this.$route.query.page ?? 1;
      this.$store.dispatch('announcements/getAll', page ).catch(error => {
        const errorMessage =
          error?.message || this.$t('AUDIT_LOGS.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      });
    },
    onPageChange(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      try {
        this.$store.dispatch('announcements/getAll',  page );
      } catch (error) {
        const errorMessage =
          error?.message || this.$t('AUDIT_LOGS.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    openAnnouncementModal(announcement) {
      this.$refs.announcementModal.showAnnouncementModal = true
      this.announcement = announcement
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    }
  },
};
</script>
