/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search=${searchKey}` : this.url;
    return axios.get(url);
  }

  create(data) {
    const formData = new FormData();
    formData.append("canned_response[short_code]", data.short_code)
    formData.append("canned_response[content]", data.content)
    if (data["file"]) formData.append("file", data.file)
    return axios.post(this.url, formData,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  update(id, data) {
    const formData = new FormData();
    formData.append("canned_response[short_code]", data.short_code)
    formData.append("canned_response[content]", data.content)
    formData.append("canned_response[id]", data.id)
    if (data["file"]) formData.append("file", data.file)
    return axios.patch(`${this.url}/${id}`, formData,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new CannedResponse();
