<template>
  <div class="h-full">
    <iframe v-if="iframeURL != '' " :src="iframeURL" height="100%" width="100%"  allow=" clipboard-read;clipboard-write;" />    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountAPI from '../../../../api/account'

export default {
  data() {
    return { 
      showAddPopup: false,
      iframeURL: ''
    };
  },
  mounted() {
    this.$store.dispatch('campaigns/get');
    this.setIframeURL()
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    async setIframeURL() {
      const key = await this.getRandomKey();
      this.iframeURL = 
        window.chatwootConfig.ecommereceUrl+
         key +`&dark_mode=${localStorage.color_scheme === 'dark'}&locale=${this.$root.$i18n.locale}`
      
    },
    async getRandomKey() {
      const response = await AccountAPI.getRandomKey()
      return response.data.account_random_key
    }
  },
};
</script>

<style scoped>
.h-full {
  height: 100%;
}
</style>
