/* global axios */
import ApiClient from './ApiClient';

class AnnouncementAPI extends ApiClient {
  constructor() {
    super('announcements', { accountScoped: true });
  }

  get(page ) {
    const url = page ? `${this.url}?page=${page}` : this.url;
    return axios.get(url);
  }

  getBanners() {
    const url = this.url+"/banner";
    return axios.get(url);
  }
  
}

export default new AnnouncementAPI();
