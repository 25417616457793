import ApiClient from './ApiClient';

class BulkActionsAPI extends ApiClient {
  constructor() {
    super('bulk_actions', { accountScoped: true });
  }
  async markAllread(data) {
    const url =`${this.url}/mark_all_read`;
    console.log(data)
    axios.post(url,data)
  }
  async markUnread(data) {
    const url =`${this.url}/mark_unread`;
    console.log(url,data)
    axios.post(url,data)
  }
}

export default new BulkActionsAPI();
