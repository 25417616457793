import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AnnouncementAPI from '../../api/announcement';


export const state = {
  records: null,
  allAnnouncements: null,
  uiFlags: {
    isFetching: false,
  },
  banners: null,
  meta: { }
};

export const getters = {
  get($state) {
    return $state.records;
  },
  getAll($state) {
    return $state.allAnnouncements;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getMeta(_state) {
    return _state.meta;
  },
  getBanners($state) {
    return $state.banners;
  }
}

export const actions = {
  get: async ({ commit }) => {
    // commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AnnouncementAPI.get();
      // commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_ANNOUNCEMENT, response.data.announcements);
    } catch (error) {
      console.log(error)
      // commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
  getBanners: async ({ commit }) => {
    // commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AnnouncementAPI.getBanners();
      // commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      console.log(response.data.banners)
      commit(types.default.SET_BANNERS, response.data.banners);
    } catch (error) {
      console.log(error)
      // commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
  getAll: async ({ commit },  page ) => {
    // commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AnnouncementAPI.get(page);
      // commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_ALL_ANNOUNCEMENT, response.data.announcements);
      commit(types.default.SET_ANNOUNCEMENT_META, response.data.meta);
    } catch (error) {
      console.log(error)
      // commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
}

export const mutations = {
  [types.default.SET_ANNOUNCEMENT]: MutationHelpers.set,
  [types.default.SET_ALL_ANNOUNCEMENT]($state, announcements){
    $state.allAnnouncements = announcements
  },
  [types.default.SET_BANNERS]($state, banners){
    $state.banners = banners
  },
  [types.default.SET_ANNOUNCEMENT_META]($state, meta){
    $state.meta = meta
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};