/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  create(payload) {
    let formData = payload
    if (payload.image) {
      formData = new FormData();
      
      formData.append('message[image]', payload.image)
      
      formData.append('message[content]', payload.message.content);
      for(var key in payload.message.template_params) {
        if (key == 'processed_params') continue; 
        formData.append(`message[template_params[${key}]]`, payload.message.template_params[key])
      }
      for(var key in payload.message.template_params['processed_params']) {
        formData.append(`message[template_params[processed_params][${key}]]`, payload.message.template_params["processed_params"][key])
      }
      
      formData.append('assignee_id', payload.assignee_id)
      formData.append('contact_id', payload.contact_id)
      formData.append('inbox_id', payload.inbox_id)
      formData.append('source_id', payload.source_id)

      return super.create(formData)
    }
    else {
      return super.create(formData)
    }
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }
}

export default new ConversationApi();
